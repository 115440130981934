import React, { Fragment } from "react"
import navbarStyles from "./navbar.module.css"
import { Link } from "gatsby"
import { FaAngleDown, FaBars, FaTimes } from "react-icons/fa"
import LogoWhite from "./logo-white"
import LogoBlack from "./logo-black"
import imageLogoWhite from "../images/logo-white.svg"
import imageLogoBlack from "../images/logo-black.svg"

const ListLink = props => (
  <li style={{ display: `inline-block`, marginRight: `2rem` }}>
    <Link to={props.to}>{props.children}</Link>
  </li>
)

const ListLinkButton = props => (
  <li style={{ display: `inline-block`, marginRight: `2rem` }}>
    <Link to="/contact">
      <div className={navbarStyles.button}>Contact</div>
    </Link>
  </li>
)

const ListLinkDropdown = props => (
  <li style={{ display: `inline-block`, marginRight: `2rem` }}>
    <Link to="/">
      Products
      <FaAngleDown
        style={{
          fontSize: "1.2em",
          verticalAlign: "middle",
          marginLeft: 2,
        }}
      />
    </Link>
    <div className={navbarStyles.dropdownContent}>
      <div className={navbarStyles.speechBubble} />
      <Link to="/healthcare">Healthcare</Link>
      <Link to="/enterprise">Enterprise</Link>
    </div>
  </li>
)

let state = { scrolledNav: false, showNavMenu: false }

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = state
    this.handleClickShowNavMenu = this.handleClickShowNavMenu.bind(this)
    this.handleClickHideNavMenu = this.handleClickHideNavMenu.bind(this)
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll = ev => {
    let lastScrollY = 0
    lastScrollY = window.scrollY
    if (lastScrollY > 50) {
      this.setState({ scrolledNav: true })
    } else {
      this.setState({ scrolledNav: false })
    }
  }

  handleClickShowNavMenu(e) {
    console.log("hi")
    e.preventDefault()
    this.setState({ showNavMenu: true })
  }

  handleClickHideNavMenu(e) {
    console.log("sub")
    e.preventDefault()
    this.setState({ showNavMenu: false })
  }

  render() {
    //const { children, data } = this.props
    //console.log(this.state.addClass);
    let navClass = [navbarStyles.navbar]
    let logo = <img src={imageLogoWhite} height="50px" />
    if (this.state.scrolledNav) {
      navClass.push(navbarStyles.scrollednav)
      logo = <img src={imageLogoBlack} height="50px" />
    }
    let navbarmenuClass = [navbarStyles.navbarMenu]
    if (this.state.showNavMenu) {
      navbarmenuClass.push(navbarStyles.navbarMenuOpen)
    }
    return (
      <Fragment>
        <div className={navbarStyles.navbarOuter}>
          <nav className={navClass.join(" ")} ref={this.nav}>
            <div className={navbarStyles.navbarContainer}>
              <div>
                <Link to="/">{logo}</Link>
              </div>
              <ul className={navbarStyles.desktop}>
                <ListLinkDropdown />
                <ListLink to="/partner">Partner</ListLink>
                <ListLink to="/about">About</ListLink>
                {/* <ListLink to="/support">Support</ListLink> */}
                <ListLinkButton />
              </ul>
              <ul className={navbarStyles.mobile}>
                <div
                  className={navbarStyles.navbarBurger}
                  onClick={this.handleClickShowNavMenu}
                >
                  <a href="#">
                    <FaBars />
                  </a>
                </div>
                <div className={navbarmenuClass.join(" ")}>
                  <div className={navbarStyles.navbarMenuContainer}>
                    <div className={navbarStyles.navbarMenuTop}>
                      <LogoWhite />
                      <a href="#" onClick={this.handleClickHideNavMenu}>
                        <FaTimes />
                      </a>
                    </div>

                    <ul>
                      <li onClick={this.handleClickHideNavMenu}>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        Products{" "}
                        <FaAngleDown style={{ verticalAlign: "middle" }} />
                      </li>
                      <div className={navbarStyles.navbarSubMenu}>
                        <li onClick={this.handleClickHideNavMenu}>
                          <Link to="/healthcare">Healthcare</Link>
                        </li>
                        <li onClick={this.handleClickHideNavMenu}>
                          <Link to="/enterprise">Enterprise</Link>
                        </li>
                      </div>
                      <li
                        onClick={this.handleClickHideNavMenu}
                        style={{ marginTop: "0px" }}
                      >
                        <Link to="/partner">Partner</Link>
                      </li>
                      <li onClick={this.handleClickHideNavMenu}>
                        <Link to="/about">About</Link>
                      </li>
                      {/* <li onClick={this.handleClickHideNavMenu}>
                        <Link to="/support">Support</Link>
                      </li> */}
                      <li onClick={this.handleClickHideNavMenu}>
                        <Link to="/contact">Contact</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </ul>
            </div>
          </nav>
        </div>
      </Fragment>
    )
  }
}
export default Navbar

// var navbar = document.getElementById("navbar");
// window.onscroll = function() {myFunction()};
// var sticky = navbar.offsetTop;

// function myFunction() {
//   console.log("scrolling")
//   if (window.pageYOffset >= sticky) {
//     navbar.classList.add("sticky")
//   } else {
//     navbar.classList.remove("sticky");
//   }
// };
