import React, { Fragment } from "react"
import Navbar from "../components/navbar"
import dghvideo from "../videos/dghvideo.mp4"
import styles from "./layout.module.css"

import { Helmet } from "react-helmet"
import SEO from "../components/seo"

import CookieConsent from "react-cookie-consent"

export const SimpleSEO = () => (
  <Fragment>
    <SEO
      title="Home"
      keywords={[`VR`, `CPR`, `BLS`, `ACLS`, `ALS`, `First Aid Training`]}
    />
    <Helmet>
      <link rel="stylesheet" href="https://use.typekit.net/uhl6zac.css" />
      <script>
        {`window.pipedriveLeadboosterConfig = {base: 'leadbooster-chat.pipedrive.com',companyId: 4767695,playbookId: 1286};`}
      </script>
      <script
        src="https://leadbooster-chat.pipedrive.com/assets/loader.js"
        async
      />
    </Helmet>
    <CookieConsent
      // debug={true}
      style={{ fontSize: "12px" }}
      buttonText="Accept"
      buttonStyle={{
        background: "var(--main-orange)",
        fontWeight: "bold",
        fontFamily: "freight-sans-pro, sans-serif",
        fontSize: "16px",
      }}
    >
      By clicking on an item or other link on the website or Accept, you agree
      to the use of cookies and other technologies to process your personal data
      to enhance and personalise your experience.
    </CookieConsent>
  </Fragment>
)

export const Layout = props => {
  return (
    <Fragment>
      <SimpleSEO />
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      {!props.image && (
        <div className={styles.videoContainer}>
          <video className={styles.video} autoPlay loop muted>
            <source src={dghvideo} type="video/mp4" />
          </video>
        </div>
      )}

      <div className={styles.videoBottom} />

      {props.image && (
        <div className={styles.videoContainer}>
          <div className={styles.background}>
            <img src={props.image} alt="" />
          </div>
        </div>
      )}

      <div className={styles.layout}>
        <Navbar />
        {props.children}
      </div>
    </Fragment>
  )
}

export const SimpleLayout = props => (
  <div style={props.style}>
    <div className={styles.layout}>{props.children}</div>
  </div>
)

export default Layout
